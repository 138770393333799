import * as React from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"
import { studioLinkPrefix } from "../../config/link"
import { locale, Locale } from "../../config/locale"
import Button from "../button"

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const URLPrepend = `${ studioLinkPrefix + (locale === Locale.Chinese ? "" : "/account/register?email=") }`

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 75%;

  @media only screen and (max-width: 575.98px) {
    width: 100%;
    flex-direction: column;
  }
`

const StyledInput = styled.input`
  padding: 8px 16px;
  border-width: 4px;
  border-style: solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  max-width: 100%;
  flex-grow: 1;
  font-size: 24px;

  @media only screen and (max-width: 575.98px) {
    width: 100%;
    border-radius: 4px;
    font-size: 20px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 272px;

  button {
    padding: 20px 11px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 100%;
  }

  @media only screen and (max-width: 575.98px) {
    flex-direction: column;
    max-width: 100%;

    * {
      width: 100%;
    }

    button {
      width: 100%;
      max-width: 100%;
      margin-top: 8px;
      border-radius: 4px !important;
    }
  }

  @media only screen and (max-width: 575.98px) {
    border-left: none;
  }
`

interface RegisterFormState {
  email: string
  isValid: boolean
}

export default class RegisterForm extends React.Component<{}, RegisterFormState> {
  state: RegisterFormState = {
    email: "",
    isValid: false
  }

  updateValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value

    this.setState(prevState => ({
      email: value,
      isValid: EMAIL_REGEX.test(value)
    }))
  }

  submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { email } = this.state

    if (window) {
      window.open(`${ URLPrepend }${ encodeURIComponent(email) }`, "_blank")
    }
  }

  render() {
    const { isValid } = this.state

    return (
      <Form onSubmit={this.submitForm}>
        <StyledInput
          className="P700-border"
          placeholder="Business Email Address"
          onChange={this.updateValue}
        />

        <ButtonContainer>
          <Button
            title={<FormattedMessage id="registration-form.button-title" />}
            className="large"
            disabled={!isValid}
          />
        </ButtonContainer>
      </Form>
    )
  }
}