import hero from "assets/images/landing-hero.png"
import hero2x from "assets/images/landing-hero@2x.png"
import hero3x from "assets/images/landing-hero@3x.png"
import hero4x from "assets/images/landing-hero@4x.png"
import hero5x from "assets/images/landing-hero@5x.png"
import phone from "assets/images/phone.svg"
import chatExample from "assets/png/web-messenger-lead-gen.png"
import channelAndroid from "assets/svg/android.svg"
import channelApple from "assets/svg/apple.svg"
import bubbleAnalytics from "assets/svg/bubble-analytics.svg"
import bubbleBots from "assets/svg/bubble-bots.svg"
import bubbleEducation from "assets/svg/bubble-education.svg"
import bubbleFinance from "assets/svg/bubble-finance.svg"
import bubbleNlp from "assets/svg/bubble-nlp.svg"
import bubbleRetail from "assets/svg/bubble-retail.svg"
import bubbleVoice from "assets/svg/bubble-speech.svg"
import bubbleTravel from "assets/svg/bubble-travel.svg"
import conversationsChannels from "assets/svg/conversation-channels.svg"
import conversationsRetention from "assets/svg/conversation-retention.svg"
import conversationsAtScale from "assets/svg/conversations-at-scale.svg"
import enterpriseSecurityBg from "assets/svg/enterprise-bg.svg"
import enterpriseShield from "assets/svg/enterprise-shield.svg"
import hypnoSquare from "assets/svg/hypno-square.svg"
import channelFbMessenger from "assets/svg/messenger.svg"
import channelMic from "assets/svg/mic.svg"
import channelMiniProgram from "assets/svg/miniprogram.svg"
import screenshotBg from "assets/svg/screenshot-bg.svg"
import channelSpeech from "assets/svg/speech.svg"
import triangleBlue from "assets/svg/triangle-blue.svg"
import triangleGreen from "assets/svg/triangle-green.svg"
import triangleYellow from "assets/svg/triangle-yellow.svg"
import channelVoice from "assets/svg/voice.svg"
import channelWeb from "assets/svg/web.svg"
import channelWeChat from "assets/svg/wechat.svg"
import BackgroundContainer from "components/bg-container"
import Button from "components/button"
import { A, DividedSection, H1, H2, HomeAnimation, P } from "components/design-system"
import HubspotForm from "components/hubspot-form"
import Image from "components/image"
import Card from "components/new-card"
import Section, { Column } from "components/new-section"
import RegisterForm from "components/register-form"
import { studioLinkPrefix } from "config/link"
import { locale } from "config/locale"
import { Link } from "gatsby"
import Lottie from "lottie-web"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import Layout from "templates/layout"

export default class Landing extends React.Component {
  componentDidMount() {
    Lottie.loadAnimation({
      autoplay: true,
      container: document.getElementById("animation") as HTMLElement, // the dom element that will contain the animation
      loop: true,
      path: "animations/messenger-animation.json",
      renderer: "svg",
    })
  }

  render() {
    return (
      <Layout title="Home | Stackchat">

        {/* Hero Section */}
        <BackgroundContainer
          image={[
            `url('${ hero5x }')`,
            `url('${ hero4x }')`,
            `url('${ hero3x }')`,
            `url('${ hero2x }')`,
            `url('${ hero }')`
          ]}
          classes="N10-bg space-inner-top-10 space-inner-bottom-10"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "bottom 0 left 0"
          }}
        >
          <Section
            partition="full-width"
            classes="home-hero-section"
          >
            <Column className="col-12 col-md-6">
              <H2 className="N0 space-outer-top-8">
                <FormattedMessage id="home.hero.heading" />
              </H2>
              <P className="space-outer-top-2 N0">
                <FormattedMessage id="home.hero.description1" />
              </P>
              <P className="space-outer-top-2 N0">
                <FormattedMessage id="home.hero.description2" />
              </P>

              <A
                href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
                target="_blank"
              >
                <Button
                  title={<FormattedMessage id="home.hero.link" />}
                  className="large space-outer-top-6"
                  inverse={true}
                />
              </A>
            </Column>

            <BackgroundContainer
              image={`url("${ phone }")`}
              classes="col-12 col-md-6 animation-background"
            >
              <HomeAnimation id="animation" />
            </BackgroundContainer>
          </Section>
        </BackgroundContainer>

        {/* Business Registration Form */}
        <Section
          partition="full-width"
          classes="N10-bg"
          style={{
            clipPath: "polygon(0 90%, 21% 100%, 44% 90%, 65% 100%, 83% 88%, 100% 100%, 100% 0%, 0% 0%)"
          }}
        >
          <Column
            className="col-12 col-md-10 offset-md-1 d-flex flex-column align-items-center space-inner-top-4 space-inner-bottom-8"
          >
            <H2 className="space-outer-bottom-1 text-center">
              <FormattedMessage id="home.hero.cta.heading" />
            </H2>
            <RegisterForm />
            <P className="space-outer-top-1 text-center">
              <FormattedMessage id="home.hero.cta.description" />
            </P>
          </Column>
        </Section>

        {/* Attract Section */}
        <Section
          id="attract"
          partition="60-40"
          classes="space-outer-top-4 space-outer-bottom-8"
          inverse={true}
          leftChild={(
            <BackgroundContainer
              image={`url("${ screenshotBg }")`}
              style={{
                height: "100%",
                backgroundPosition: "50% 50%"
              }}
              classes="d-flex flex-column justify-content-center align-items-center">
              <Image src={chatExample} maxWidth="300px" showShadow={true} classes="space-outer-top-10" />
            </BackgroundContainer>
          )}
          rightChild={(
            <DividedSection
              left={true}
              image={triangleGreen}
              className="d-flex flex-column justify-content-center"
            >
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="home.attract.heading" />
              </H1>

              <P>
                <FormattedMessage id="home.attract.description" />
              </P>

              {/* <MainLink text="home.attract.link" link="#" /> */}
            </DividedSection>
          )}
        />

        {/* Engage Section */}
        <Section
          id="engage"
          classes="N10-bg space-inner-top-8 space-inner-bottom-10"
          style={{
            clipPath: "polygon(2px 94.16%, 21% 100%, 43.67% 93.96%, 64.92% 100.2%, 83.50% 93.55%, 100% 100%, 100% 0%, 0% 0%)"
          }}
          partition="60-40"
          leftChild={(
            <div
              style={{ height: "100%" }}
              className="d-flex"
            >
              <Image src={conversationsAtScale} />
            </div>
          )}
          rightChild={(
            <DividedSection
              image={triangleYellow}
              position="top 0 left 16%"
              className="d-flex flex-column justify-content-center"
            >
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="home.engage.heading" />
              </H1>

              <P>
                <FormattedMessage id="home.engage.description" />
              </P>

              {/* <MainLink text="home.engage.link" link="#" /> */}
            </DividedSection>
          )}
        />

        {/* Retain Section */}
        <Section
          id="retain"
          partition="60-40"
          classes="space-inner-top-6 space-inner-bottom-6"
          inverse={true}
          leftChild={(
            <div
              style={{ height: "100%" }}
              className="d-flex justify-content-end"
            >
              <Image src={conversationsRetention} />
            </div>
          )}
          rightChild={(
            <DividedSection
              left={true}
              image={triangleBlue}
              position="top 4% left 0%"
              style={{
                backgroundSize: "50% auto",
              }}
              className="d-flex flex-column justify-content-center"
            >
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="home.retain.heading" />
              </H1>

              <P>
                <FormattedMessage id="home.retain.description" />
              </P>

              {/* <MainLink text="home.retain.link" link="#" /> */}
            </DividedSection>
          )}
        />

        {/* Connect Section */}
        <Section
          classes="space-inner-top-6 space-inner-bottom-6 N10-bg"
          style={{
            clipPath: "polygon(0px 100%, 100% 100%, 100% 6%, 71.90% 10.97%, 57.62% 0, 1px 6.6%)"
          }}
          partition="60-40"
          leftChild={(
            <div
              style={{ height: "100%" }}
              className="space-inner-top-6 space-inner-bottom-6 d-flex"
            >
              <Image src={conversationsChannels} />
            </div>
          )}
          rightChild={(
            <DividedSection
              image={triangleYellow}
              position="top 16% left 16%"
              className="d-flex flex-column justify-content-center"
            >
              <H1 className="space-outer-top-0 space-outer-bottom-2">
                <FormattedMessage id="home.connect.heading" />
              </H1>

              <P>
                <FormattedMessage id="home.connect.description" />
              </P>

              {/* <MainLink text="home.connect.link" link="#" /> */}
            </DividedSection>
          )}
        />

        {/* Enterprise Grade Security */}
        <BackgroundContainer
          image={`url('${ enterpriseSecurityBg }')`}
          classes="space-inner-top-6 space-inner-bottom-6"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "50% 50%"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <Column className="col-12 col-md-2 d-flex align-items-start">
                <Image src={enterpriseShield} maxWidth="160px" classes="ml-auto mr-auto" />
              </Column>

              <Column className="col-12 col-md-10">
                <H1 className="space-outer-bottom-1 N0 mt-4 mt-sm-0 text-center text-md-left">
                  <FormattedMessage id="home.enterprise.heading" />
                </H1>

                <P className="N0 text-center text-md-left">
                  <FormattedMessage id="home.enterprise.description" />
                </P>

                {/* <MainLink
                  text="home.enterprise.link"
                  link="#"
                  classes="N0 space-outer-top-2 text-center text-md-left"
                /> */}
              </Column>
            </div>
          </div>
        </BackgroundContainer>

        {/* Start Building */}
        <Section
          partition="full-width"
          classes="space-inner-top-6"
        >
          <Column className="col-12 col-md-8 offset-md-2 text-center">
            <H1 className="space-outer-top-0 space-outer-bottom-1">
              <FormattedMessage id="home.features.heading" />
            </H1>

            <P>
              <FormattedMessage id="home.features.description" />
            </P>
          </Column>
        </Section>

        <Section
          partition="full-width"
        >
          <Card
            title="home.features.card1.title"
            description="home.features.card1.description"
            image={bubbleNlp}
          >
            {/* <MainLink
              text="home.features.card1.link"
              link="#"
              classes="P500 space-outer-top-1" /> */}
          </Card>

          <Card
            title="home.features.card2.title"
            description="home.features.card2.description"
            image={bubbleVoice}
          >
            {/* <MainLink
              text="home.features.card2.link"
              link="#"
              classes="G500 space-outer-top-1" /> */}
          </Card>

          <Card
            title="home.features.card3.title"
            description="home.features.card3.description"
            image={bubbleBots}
          >
            {/* <MainLink
              text="home.features.card3.link"
              link="#"
              classes="Y500 space-outer-top-1" /> */}
          </Card>

          <Card
            title="home.features.card4.title"
            description="home.features.card4.description"
            image={bubbleAnalytics}
          >
            {/* <MainLink
              text="home.features.card4.link"
              link="#"
              classes="B500 space-outer-top-1" /> */}
          </Card>
        </Section>

        <Section
          partition="full-width"
          classes="space-inner-top-5 space-outer-bottom-6"
        >
          <Column className="col-12 space-outer-bottom-4 text-center">
            <A
              href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
              target="_blank"
              style={{ border: "none" }}
            >
              <Button
                title={<FormattedMessage id="home.features.link" />}
                className="large"
              />
            </A>
          </Column>
        </Section>

        {/* Subscribe */}
        <BackgroundContainer
          image={`url(${ hypnoSquare }), url(${ hypnoSquare })`}
          classes="P500-bg"
          hideBackgroundOnMobile={true}
          style={{
            backgroundPosition: "top 0 left -10%, top 0 right -10%"
          }}
        >
          <Section
            partition="full-width"
            classes="space-inner-top-4 space-inner-bottom-4"
          >
            <Column className="col-12 d-flex flex-column text-center">
              <H1 className="N0 space-outer-bottom-0">
                <FormattedMessage id="home.subscribe.heading" />
              </H1>

              <P className="N0">
                <FormattedMessage id="home.subscribe.description" />
              </P>

              <HubspotForm
                portalId="3371700"
                formId="9b8a3d5c-0ca2-415a-9403-d8de927e237e"
              />
            </Column>
          </Section>
        </BackgroundContainer>

        {/* Scale Growth */}
        <Section
          partition="full-width"
          classes="space-inner-top-6"
        >
          <Column className="col-12 col-md-8 offset-md-2 text-center">
            <H1 className="space-outer-top-0 space-outer-bottom-1">
              <FormattedMessage id="home.industry.heading" />
            </H1>

            <P>
              <FormattedMessage id="home.industry.description" />
            </P>
          </Column>
        </Section>

        <Section
          partition="full-width"
        >
          <Card
            title="home.industry.card1.title"
            description="home.industry.card1.description"
            image={bubbleTravel}
          >
            {/* <MainLink
              text="home.industry.card1.link"
              link="#"
              classes="P500 space-outer-top-1" /> */}
          </Card>

          <Card
            title="home.industry.card2.title"
            description="home.industry.card2.description"
            image={bubbleEducation}
          >
            {/* <MainLink
              text="home.industry.card2.link"
              link="#"
              classes="G500 space-outer-top-1" /> */}
          </Card>

          <Card
            title="home.industry.card3.title"
            description="home.industry.card3.description"
            image={bubbleFinance}
          >
            {/* <MainLink
              text="home.industry.card3.link"
              link="#"
              classes="Y500 space-outer-top-1" /> */}
          </Card>

          <Card
            title="home.industry.card4.title"
            description="home.industry.card4.description"
            image={bubbleRetail}
          >
            {/* <MainLink
              text="home.industry.card4.link"
              link="#"
              classes="B500 space-outer-top-1" /> */}
          </Card>
        </Section>

        <Section
          partition="full-width"
          classes="space-inner-top-5 space-outer-bottom-6"
        >
          <Column className="col-12 space-outer-bottom-4 text-center">
            <div
              style={{ maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}
              className="d-flex flex-column flex-sm-row justify-content-between"
            >
              <Link to="/contact">
                <Button
                  title={<FormattedMessage id="home.industry.link1" />}
                  className="large space-outer-top-1 space-outer-bottom-1"
                />
              </Link>

              <Link to="/pricing">
                <Button
                  title={<FormattedMessage id="home.industry.link2" />}
                  className="large space-outer-top-1 space-outer-bottom-1"
                  hollow={true}
                />
              </Link>
            </div>
          </Column>
        </Section>

        {/* Logos */}
        <div className="container-fluid P500-bg space-inner-top-2 space-inner-bottom-4">
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <Image
              src={channelFbMessenger}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
            <Image
              src={channelWeChat}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
            <Image
              src={channelMic}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
            <Image
              src={channelWeb}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
            <Image
              src={channelApple}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
            <Image
              src={channelAndroid}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
            <Image
              src={channelMiniProgram}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
            <Image
              src={channelVoice}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
            <Image
              src={channelSpeech}
              maxHeight="64px"
              maxWidth="64px"
              classes="space-outer-top-3 space-outer-bottom-3 space-outer-left-3 space-outer-right-3"
            />
          </div>

          {/* <div className="text-center">
            <MainLink text="home.channels.link" link="" classes="N0" />
          </div> */}
        </div>

        {/* Ready to Get Started */}
        <Section
          partition="full-width"
          classes="space-inner-top-6 space-inner-bottom-6"
        >
          <Column className="col-12 text-center">
            <H1 className="space-outer-bottom-0">
              <FormattedMessage id="home.cta2.heading" />
            </H1>

            <P>
              <FormattedMessage id="home.cta2.description" />
            </P>
          </Column>

          <Column
            className="col-12 space-outer-top-3 space-outer-bottom-3"
          >
            <div
              style={{ maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}
              className="d-flex flex-column flex-sm-row justify-content-between"
            >
              <Link to="/contact">
                <Button
                  title={<FormattedMessage id="home.cta2.link1" />}
                  className="large space-outer-top-1 space-outer-bottom-1"
                />
              </Link>

              <A
                href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
                target="_blank"
                style={{ border: "none" }}
              >
                <Button
                  title={<FormattedMessage id="home.cta2.link2" />}
                  className="large space-outer-top-1 space-outer-bottom-1"
                  hollow={true}
                />
              </A>
            </div>
          </Column>
        </Section>
      </Layout>
    )
  }
}